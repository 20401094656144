export default {
  state: {
    showLoading: false,
    info: 'Loading...',
    status: 'loading'
  },
  reducers: {
    setShowLoading (state, flag) {
      state.showLoading = flag
    },
    setInfo (state, info) {
      state.info = info
    },
    setStatus (state, status) {
      state.status = status
    }
  }
}
