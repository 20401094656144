export default {
  state: {
    isCompressImg: true, // 是否压缩图片
    isNotifacation: true // 是否提示消息
  },
  reducers: {
    setCompressImg (state, flag) {
      state.isCompressImg = flag
    },
    setNotifacation (state, flag) {
      state.isNotifacation = flag
    }
  }
}
