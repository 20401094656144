import React, { useCallback, useEffect, useRef, useContext } from 'react'
import { useMappedState } from 'redux-react-hook'
import List from './List'
import Clipboard from 'clipboard'
import './messageList.scss'
import Viewer from 'viewerjs'

import { clearMessage, clearFile, delItem } from '../../api/message'
import { Modal, Toast, Icon } from 'antd-mobile'
import { AppContext } from '@/utils'
import dayjs from 'dayjs'

const prompt = Modal.prompt;

export default function (props) {
  const viewer = useRef(null)
  const { socket } = useContext(AppContext)
  let { list, setList, startToast } = props

  const mapState = useCallback(state => ({ message: state.message }), [])
  const { message: { isNewMessage } } = useMappedState(mapState)

  // 挂载图片进行预览
  useEffect(() => {
    let mountEl = document.querySelector('.images')
    // 挂载获取dom有延迟，添加定时器
    setTimeout(() => {
      viewer.current = new Viewer(mountEl, {
        show() {
          console.log('显示了')
        },
        hide() {
          console.log('隐藏了')
        },
        filter(image) {
          return image.dataset.complete
        }
      })
    }, 300)
    return () => {
      viewer.current && viewer.current.destroy()
    }
  }, [list])

  // 触发复制功能，不能在子元素上面写，否则会给每个消息都注册
  useEffect(() => {
    let clipboard = new Clipboard('.msg-list__copy')
    clipboard.on('success', function (e) {
      startToast('复制成功', 'success', false)
    })

    clipboard.on('error', function (e) {
      console.error('Action:', e.action);
      console.error('Trigger:', e.trigger);
    });

    return function () {
      clipboard.destroy()
    }
  }, [startToast])

  // 通过item删除单个
  const deleteItem = useCallback(async ({ id, fileName }, index) => {
    await delItem({ id, fileName })
    setList(list => {
      const curList = JSON.parse(JSON.stringify(list))
      curList.splice(index, 1)
      return curList
    })
    socket.emit('/user/list/update', id)
    window._hmt.push(['_trackEvent', '删除消息', 'delete'])
    startToast('删除成功', 'success', false)
  }, [setList, socket, startToast])

  // 清空所有消息函数
  const clearnMessage = useCallback(() => {
    clearMessage() // 清空数据库
    clearFile() // 清空远端文件
    setList([]) // 清空list
    startToast('清空成功', 'success', false)
    // localStorage.removeItem('list') // 删除localStorage
  }, [setList, startToast])

  // 清空所有消息弹窗
  const clearnPrompt = useCallback(()=>{
    prompt(
      '确定要清空全部消息吗？',
      '您需要输入密码才能清空',
      [
        { text: '取消' },
        { text: '提交', onPress: password => new Promise(
          (resolve, reject) => {
            if(password === '1122'){
              clearnMessage()
              resolve()
            } else {
              Toast.info('密码输入错误, 请重新输入', 1)
              reject()
            }
          }
        ) }
      ],
      'default',
      null,
      ['请输入密码']
    )
  }, [clearnMessage])

  return (
    <div className='msg-list'>
      <div className='msg-list__scroll'>
        <ul className='images'>
          {!!list.length ?
            list.map((item, index) => (
              <li key={index} className={`msg-list__item ${(index === 0 && isNewMessage) ? 'fade-in' : ''}`}>
                <div className="msg-list__item__date">
                  {dayjs(item.date).format('YYYY-MM-DD HH:mm:ss')}
                </div>
                <List item={item} />
                <Icon
                  type='cross-circle'
                  color='#ddd'
                  className='msg-list__item__delete'
                  style={{ width:16 }}
                  onClick={()=>deleteItem(item, index)}
                />
              </li>
            ))
            : <div className='msg-list__empty'>
              <span>支持拖拽发送</span>
            </div>
          }
        </ul>
        <span className="clears" onClick={clearnPrompt}>X</span>
      </div>
    </div>
  )
}
