export default {
  state: {
    hash: ''
  },
  reducers: {
    setHash(state, hash) {
      state.hash = hash
    }
  }
}
