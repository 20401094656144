import React, { useCallback } from 'react'
import { useMappedState } from 'redux-react-hook'
import { CSSTransition } from 'react-transition-group'
import globalConfig from '@/config'
import './oprationBtn.scss'

export default function (props) {
  let { setKey, myKey } = props
  const mapState = useCallback(state => ({
    isCompressImg: state.oprationBtn.isCompressImg,
    isNotifacation: state.oprationBtn.isNotifacation
  }), [])
  const { isCompressImg, isNotifacation } = useMappedState(mapState)
  return (
    <ul className='opration-btn-group'>
      <li>
        <span className='span'>发送消息按键：</span>
        <input type="radio" hidden name="send-options" onChange={() => setKey('enter')} className='item' defaultChecked={myKey === 'enter'} id="enter-radio" />
        <label htmlFor="enter-radio">
          <span>enter</span>
        </label>

        <input type="radio" hidden name="send-options" onChange={() => setKey('ctrl-enter')} className='item' defaultChecked={myKey === 'ctrl-enter'} id="ctrl_enter-radio" />
        <label htmlFor="ctrl_enter-radio" >
          <span>ctrl+enter</span>
        </label>
      </li>

      <li>
        <span className='span'>PC端是否通知消息：</span>
        <input type="radio" hidden name="is-notice-online" onChange={() => globalConfig.dispatch.oprationBtn.setNotifacation(true)} className='item' defaultChecked={isNotifacation} id="notice-online" />
        <label htmlFor="notice-online">
          <span>是</span>
        </label>

        <input type="radio" hidden name="is-notice-online" onChange={() => globalConfig.dispatch.oprationBtn.setNotifacation(false)} className='item' defaultChecked={!isNotifacation} id="no-notice-online" />
        <label htmlFor="no-notice-online" >
          <span>否</span>
        </label>
      </li>
      <li>

        <span className="span">是否压缩图片：</span>
        <input type="radio" onChange={() => globalConfig.dispatch.oprationBtn.setCompressImg(true)} defaultChecked={isCompressImg} hidden name='compress-img' id='yes' />
        <label htmlFor="yes">
          <span>是</span>
        </label>
        <input type="radio" onChange={() => globalConfig.dispatch.oprationBtn.setCompressImg(false)} defaultChecked={!isCompressImg} hidden name='compress-img' id='no' />
        <label htmlFor="no" style={{ marginRight: '30px' }}>
          <span>否</span>
        </label>
        <CSSTransition
          appear={true}
          unmountOnExit
          className='fade'
          timeout={2000}
          in={!isCompressImg}
        >
          <div>
            <span className='specify'>图片不压缩上传将很慢</span>
          </div>
        </CSSTransition>
      </li>
    </ul>
  )
}
