const config = {
  mode: 'company2',
  server: {
    company: 'http://10.2.117.34:8888/', // 公司的ip地址
    company2: 'http://192.168.254.174:8888/',
    // home: 'http://192.168.1.107:8888/', // 家里的ip地址
    home: 'http://192.168.1.102:8888/', // 家里的ip地址 不用localhost的原因是因为手机没法测试
    origin: 'https://chat-serve.hrhe.cn/', // 服务器的ip地址
  },
  getCurrentServer: function () {
    let isProduction = process.env.NODE_ENV === 'production'
    return isProduction ? this.server['origin'] : this.server[this.mode]
  },
  dispatch: null,
}
export default config
