import React, { useCallback } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useMappedState } from 'redux-react-hook'
import './index.scss'

const Message = () => {
  // 获取状态值
  const mapState = useCallback(state => ({ toast: state.toast }), [])
  const { toast: { showLoading, info, status } } = useMappedState(mapState)

  let toastEl = {
    loading: <i className='icon-Loading iconfont my-loading'></i>,
    success: <i style={{ fontSize: '25px' }} className='iconfont icon-success'></i>,
    warning: <i style={{ fontSize: '18px' }} className='iconfont icon-warning'></i>
  }

  return (
    <CSSTransition
      appear={true}
      unmountOnExit
      className='fade'
      timeout={2000}
      in={showLoading}
    >
      <div>
        <div className='toast'>
          {toastEl[status]}
          {info}
        </div>
      </div>
    </CSSTransition>
  )
}

export default Message
