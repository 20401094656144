import axios from 'axios'
import config from '../config'
import { Toast } from 'antd-mobile'

axios.defaults.baseURL = config.getCurrentServer()

axios.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => Promise.reject(error)
)

axios.interceptors.response.use(
  (response) => {
    if (response.data.code && response.data.code === 500) {
      return Promise.reject()
    }
    return response.data
  },
  (error) => {
    Toast.fail('服务器出错了, 请联系开发者')
    return Promise.reject(error)
  }
)

export const getData = (
  url,
  data,
  method = 'get',
  ContentType = 'application/json'
) => {
  let config = {
    url,
    data,
    method: method.toLowerCase(),
    headers: {
      'Content-Type': ContentType,
    },
  }
  if (method.toLowerCase() === 'get') {
    config.params = data
  }
  return axios(config)
}
