import React from 'react'
import { render } from 'react-dom'
import config from './config'
import IO from 'socket.io-client'
import { StoreContext } from 'redux-react-hook'
import store from './store'
import { AppContext } from '@/utils'

import App from './App'
import Message from './components/common/Message'

import 'antd-mobile/dist/antd-mobile.css'
import 'viewerjs/dist/viewer.css'

// Notification.requestPermission()

const path = config.getCurrentServer()
const socket = IO(path)
render(
  <StoreContext.Provider value={store}>
    <AppContext.Provider value={{ socket }}>
      <App />
      <Message />
    </AppContext.Provider>
  </StoreContext.Provider>,
  window.root
)
