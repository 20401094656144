import React, { useEffect, useState, useContext } from 'react'
import { Modal, WhiteSpace } from 'antd-mobile'

import { AppContext } from '@/utils'

import './header.scss'

export default ({ online }) => {
  const [visible, setVisible] = useState(false)
  const [list, setList] = useState([])
  const [name, setName] = useState('')

  const { socket } = useContext(AppContext)
  useEffect(() => {
    socket.emit('/user/list', (list, name) => {
      setList(list)
      setName(name)
    })
  }, [socket])
  return (
    <>
      <div className="header">
        消息转发工具
        {/* <span className='online' onClick={() => setVisible(true)}>当前在线人数：{online}</span> */}
        <span className='online'>当前在线人数：{online}</span>
      </div>
      <Modal
        visible={visible}
        transparent
        onClose={() => setVisible(false)}
      >
        <div>当前的name：
          <input type='text' className='header-name' defaultValue={name} />
        </div>
        <WhiteSpace />
        {
          !!list.length && list.map((item, index) => (
            <div key={index}>{item}</div>
          ))
        }
      </Modal>
    </>
  )
}