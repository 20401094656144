export default {
  state: {
    isNewMessage: false // 是否是最新消息
  },
  reducers: {
    setIsNewMessage(state, flag) {
      state.isNewMessage = flag
    }
  }
}
