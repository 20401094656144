import React from 'react'

export default ({ item, ...rest }) => {
  let el
  let { message, fileName, filePath, fileSize } = item
  if (fileName && fileName.match(/png|jpg|gif|jpeg/)) {
    el = (
      <img className='msg-list__img'  data-complete='true' src={message || filePath} alt="" />
    )
  } else if (fileName) {
    el = (
      <div className='msg-list__item__text'>
        <span>
          {fileName}
          <i className='file-size'>{fileSize}</i>
        </span>
        <a download href={filePath} className='msg-list__down'>下载</a>
        {fileName.includes('.html') && <a href={filePath} className='msg-list__down'>查看</a>}
      </div>
    )
  } else if (message) {
    // 包含http://，并匹配直到结尾或空格
    /**
     * 正则解析
     * 第一部分 (?<=(\s|^|：))
     * 第二部分 (http|https):\/\/
     * 第三部分 .*?(\.(com|cn|vip|top)|:\d{4})(?=(\s|\/|$))
     * 第四部分 .*?(?=(\s|$))
     */
    const isUrlReg = /(?<=(\s|^|：))(http|https):\/\/.*?(\.(\w+)|:\d{4})(?=(\s|\/|$)).*?(?=(\s|$))/
    let res = isUrlReg.exec(message)
    let element
    if(res) {
      let url = ''
      url = decodeURIComponent(res[0])
      element = (<span dangerouslySetInnerHTML={{
        __html: message.replace(isUrlReg, `<a href="$&" rel="noopener noreferrer" target="_blank" className="msg-list__down">${url}</a>`)
      }}></span>)

    } else {
      element = <span>{message}</span>
    }
    el = (
      <div className='msg-list__item__text'>
        {element}
        <img src={require('@/assets/copy.svg')} alt='' className='msg-list__copy' data-clipboard-text={message} />
        {/* <span className='msg-list__copy' data-clipboard-text={message}>复制</span> */}
      </div>
    )
  }
  return el
}
